import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230322-Rate-Sheet.pdf";
const date = "22.03.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Wednesday 22 March 2023 we will be making the following changes to our range:</p>

    <p>
      <strong>Summary of changes</strong>
    </p>
    <p>We have reduced our cashback incentive to £350 and £500 within our 3 and 5 Year Fixed Rate Fee Saver offerings in our First Time Buyer Range at a selection of LTV’s.</p>
    <p>We have removed our cashback incentive within our 2 and 3 Year Fixed Rate Fee Saver offerings in our First Time Buyer and Home Mover Ranges at a selection of LTV’s until further notice.</p>

    <p><strong>UK Residential Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 90% LTV has reduced</li>
      <li>5 Year Fixed Fee Saver at 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Premier Exclusive at 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 90% LTV has reduced</li>
      <li>5 Year Fixed Fee Saver at 90% LTV has reduced</li>
      <li>5 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Premier Exclusive at 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Residential First Time Buyer Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 80% and 85% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60% LTV has increased</li>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Premier Exclusive at 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Residential Home Mover Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 80% and 85% LTV have reduced</li>
      <li>3 Year Fixed Fee Saver at 60% LTV has increased</li>
      <li>3 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 80%, 85%, 90% and 95% LTV have reduced</li>
      <li>5 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Premier Exclusive at 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>UK Residential Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Standard at 80%, 85% and 90% LTV have reduced</li>
      <li>5 Year Fixed Fee Saver at 60% LTV has increased</li>
      <li>5 Year Premier Exclusive at 80%, 85% and 90% LTV have reduced</li>
    </ul>

    <p><strong>International Residential Range</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60% LTV has increased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
    </ul>

    <p>There are no changes to any other interest rates or our fixed rate end dates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Wednesday 22 March to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
